import { Formik } from 'formik';
import {
  ChatInputWrapper,
  Input,
  ChatDate,
  ChatMessage,
  LoadingSpinner,
} from '@rabbit/elements/shared-components';
import {
  ChatMessage as ChatMessageShape,
  DTConsumer_Private,
  DTRepairer_Public,
  DTTenant_Public,
  DTThread,
  DTWarrantor_Public,
  PersonaIdTypeSplitter,
  PersonaTypeSingleLetter,
} from '@rabbit/data/types';
import { fromUnixTime } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import {
  useCaseFlowCase,
  useFileStorage,
  useGetDocs,
  useSendEmail,
} from '@rabbit/bizproc/react';
import { CaseFlowCaseEditingID } from '@rabbit/bizproc/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { subscribeDocument, updateDocument } from '@rabbit/utils/api';
import { UserGroupIcon, UserIcon } from '@heroicons/react/24/solid';
import { UseQueryResult } from 'react-query';
import { FirestoreError } from 'firebase/firestore';
import { Unsubscribe } from 'firebase/auth';
import {
  CaseflowContext,
  CaseflowInterface,
} from 'apps/sage/src/context/CaseflowContext';
import {
  getConsumerURL,
  getPersonaData,
  getSageURL,
} from 'apps/sage/src/utils/helpers';
import { CORPORATE } from '@rabbit/sage/utils/consts';

export interface ChatProps {
  activePersona: string | null;
  id: CaseFlowCaseEditingID;
  tenantInfo: DTTenant_Public['info'] | undefined;
  isReadOnly?: boolean;
  caseFlowContext: {
    caseFlowCase: CaseflowInterface['caseFlowCase'];
    caseFacts: CaseflowInterface['caseFacts'];
    caseId: string;
  };
}
export interface MessageGroupProps {
  day: number;
  messages: ChatMessageShape[];
}
export interface MessageProps {
  a: string;
  body: string;
  k: number;
  t: number;
  attachment?: string;
}

interface FormValues {
  chat_input: string;
}

const initialValues: FormValues = {
  chat_input: '',
};

export function Chat({
  activePersona,
  id,
  isReadOnly = false,
  tenantInfo,
  caseFlowContext,
}: ChatProps) {
  const [selectedThread, setSelectedThread] = useState('');
  const [messageThread, setMessageThread] = useState<
    Record<string, { messages: ChatMessageShape[]; unread: number }>
  >({});
  const [chatActors, setChatActors] = useState<Record<string, string[]>>();
  const { t } = useTranslation();
  const tenantLink = t('tenantLink');
  const [actorsInfo, setActorsInfo] = useState<
    Record<string, DTWarrantor_Public | DTRepairer_Public | DTConsumer_Private>
  >({});
  const { getWarrantorData, getRepairerData, getConsumerData } = useGetDocs();
  const { uploadFiles, uploadedFiles, clearAllFilesFromState, isUpdating } =
    useFileStorage();
  const { SE_Sage_Chat_Message } = useSendEmail();

  const { caseFacts, caseFlowCase, caseId } = caseFlowContext;
  const caseActors = caseFlowCase?.GetActors();

  const moveSpotlight = async (target: PersonaTypeSingleLetter | 'NONE') => {
    const spotlightMapping: Record<string, any> = {
      [PersonaTypeSingleLetter.Consumer]: caseActors?.consumer,
      [PersonaTypeSingleLetter.Repairer]:
        caseActors?.external_repairer ?? caseActors?.repairer,
      [PersonaTypeSingleLetter.Warrantor]: caseActors?.warrantor,
      NONE: null,
    };

    const targetActor = spotlightMapping[target];

    caseFlowCase?.Alter_CaseSpotlight({
      add: targetActor ?? undefined,
      clear: true,
    });

    await caseFlowCase?.Commit();
  };

  const updateChatLastSeen = () => {
    updateDocument<{ chatLastSeen: number }>(
      `cf_actor/${activePersona}_${id.case}`,
      {
        chatLastSeen: Date.now(),
      }
    );
  };

  const sendChatMessage = async (message: string, attachment: string = '') => {
    if (!activePersona || !selectedThread || !messageThread[selectedThread])
      return;

    const thread = messageThread[selectedThread];
    thread.messages.push({
      body: message,
      attachment,
      sender: activePersona,
      timestamp: Date.now(),
    });
    setMessageThread(messageThread);

    await updateDocument(`thread/${selectedThread}`, {
      docid: selectedThread,
      messages: thread.messages,
    });
    updateChatLastSeen();

    if (chatActors && moveSpotlight) {
      const actors = chatActors[selectedThread];
      void Promise.all(
        actors.map(
          async (actor) =>
            await moveSpotlight(actor.split(PersonaIdTypeSplitter)[0] as any)
        )
      );
    }

    // Send email notification
    // Get case ID from context or props
    const currentCaseId = caseId || id.case;

    // Get recipients from chatActors for the selected thread
    const recipients =
      chatActors?.[selectedThread]?.filter(
        (actorId) => actorId !== activePersona
      ) || [];

    // Get sender info
    const senderInfo: any = actorsInfo[activePersona];
    const senderName = senderInfo.fullname || senderInfo.name;
    const senderEmail = tenantInfo?.emailInfo?.emailSender;

    // Determine if sender is consumer
    const isConsumerSender = activePersona.startsWith(
      PersonaTypeSingleLetter.Consumer
    );

    let link_to_claim: string;
    if (isConsumerSender) {
      // create claim link for sage user
      link_to_claim = `${getSageURL()}/claims/${currentCaseId}`;
    } else {
      // create claim link for consumer
      link_to_claim = `${getConsumerURL()}/repairs/${currentCaseId}`;
    }

    // Send email to each recipient
    for (const recipientId of recipients) {
      const recipientInfo: any = actorsInfo[recipientId];
      if (!recipientInfo) continue;

      await SE_Sage_Chat_Message(
        recipientInfo.email ?? '',
        senderEmail ?? '',
        t('email.subject.newMessage', {
          business_name: senderName,
          product_name: caseFacts?.consumer_holding_name ?? '',
        }),
        senderName,
        currentCaseId ?? '',
        recipientInfo.fullname,
        caseFacts?.consumer_holding_name ?? '',
        message,
        link_to_claim,
        tenantInfo?.emailInfo?.emailTemplateLanguage ?? 'en'
      );
    }
  };

  const getDaysArray = (messageHistory: ChatMessageShape[] | undefined) => {
    const daysMap = new Map();

    messageHistory &&
      messageHistory.forEach((obj) => {
        let timestamp = obj.timestamp / 1000;
        // NOW! That's what I call a dirty hack!
        if (timestamp < 505572647) {
          timestamp = Date.now() / 1000;
        }
        const date = fromUnixTime(timestamp);
        const day = date.getDate();

        if (!daysMap.has(day)) {
          daysMap.set(day, {
            day: new Date(date.getFullYear(), date.getMonth(), day),
            messages: [],
          });
        }

        daysMap.get(day).messages.push({ ...obj, t: timestamp });
      });

    return Array.from(daysMap.values());
  };

  let markReadTimeout: any;
  useEffect(() => {
    if (!selectedThread || !messageThread[selectedThread]) return;
    if (!markReadTimeout) {
      markReadTimeout = setTimeout(() => {
        updateChatLastSeen();
        messageThread[selectedThread].unread = 0;
        setMessageThread(messageThread);
      }, 10000);
    }

    return () => {
      clearTimeout(markReadTimeout);
    };
  }, [selectedThread, messageThread]);

  useEffect(() => {
    if (!caseFlowCase) return;
    const unsubs: Unsubscribe[] = [];
    const lastSeen = caseFlowCase?.GetMyChatLastSeen();
    const init = async () => {
      const actorsParams = caseFlowCase?.GetActors();
      const actors = actorsParams ? Object.values(actorsParams) : [];
      const actorInfo: any = {};
      await Promise.all(
        actors.map(async (actor) => {
          if (actor.startsWith(PersonaTypeSingleLetter.Warrantor)) {
            actorInfo[actor] = (await getWarrantorData([actor]))?.pop();
          }
          if (actor.startsWith(PersonaTypeSingleLetter.Repairer)) {
            actorInfo[actor] = (await getRepairerData([actor]))?.pop();
          }
          if (actor.startsWith(PersonaTypeSingleLetter.Consumer)) {
            actorInfo[actor] = (await getConsumerData([actor]))?.pop();
          }
          if (actor.startsWith(PersonaTypeSingleLetter.Installer)) {
            actorInfo[actor] = (await getRepairerData([actor]))?.pop();
          }
        })
      );
      setActorsInfo(actorInfo);

      if (activePersona) {
        const subs: Record<
          string,
          UseQueryResult<DTThread[], FirestoreError>
        > = {};
        const threads: Record<string, string[]> | undefined =
          caseFlowCase?.GetCaseThreads();
        const messageThread: Record<
          string,
          { messages: ChatMessageShape[]; unread: number }
        > = {};
        if (threads) {
          threads[activePersona]?.forEach((thread) => {
            if (!subs[thread]) {
              unsubs.push(
                subscribeDocument<DTThread>(`thread/${thread}`, (snapshot) => {
                  const data = snapshot.data();
                  messageThread[thread] = {
                    unread:
                      data?.messages.filter((i) => {
                        return (
                          i.timestamp > (lastSeen || 0) &&
                          i.sender !== activePersona
                        );
                      }).length ?? 0,
                    messages: data?.messages || [],
                  };
                  setMessageThread({ ...messageThread });
                })
              );
            }
          });

          const localChatActors: Record<string, string[]> = {};
          const personaThread = threads[activePersona];
          personaThread?.forEach((thread) => {
            localChatActors[thread] = localChatActors[thread] || [];
            Object.keys(threads)
              .filter((key) => key !== activePersona)
              .forEach((key) => {
                if (threads[key].indexOf(thread) !== -1) {
                  localChatActors[thread] = localChatActors[thread] || [];
                  localChatActors[thread].push(key);
                }
              });
          });
          setChatActors(localChatActors);
          setSelectedThread(threads[activePersona][0]);
        }
      }
    };

    init();

    return () => {
      unsubs.forEach((unsub) => {
        unsub();
      });
    };
  }, [caseFlowCase, activePersona]);

  const isCustomer = activePersona?.startsWith('C:');

  const handleUploadFiles = async (files: File[]) => {
    await uploadFiles(files, activePersona ?? '', 'chat_attachment');
  };

  const handleFileEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    void handleUploadFiles(chosenFiles);
  };

  useEffect(() => {
    if (uploadedFiles?.filesArr) {
      sendChatMessage('', uploadedFiles?.filesArr?.[0].url ?? '');
      clearAllFilesFromState();
    }
  }, [uploadedFiles?.filesArr]);

  const selectThread = async (thread: string) => {
    setSelectedThread(thread);
  };

  const renderCaret = (thread: string, alt: boolean = false) => (
    <span
      className={`absolute left-1/2 ${
        alt ? '-bottom-[29px]' : '-bottom-[31px]'
      } flex h-4 w-4 translate-x-[-50%] rotate-45 border-t border-l border-gray-500 bg-white ${
        selectedThread === thread ? '' : 'hidden'
      }`}
    ></span>
  );

  const renderUnread = (thread: string) => (
    <>
      {messageThread[thread] && messageThread[thread].unread > 0 && (
        <span className="bg-primary-600 font-nunito text-md absolute -top-2 -right-2 flex h-6 w-6 items-center justify-center rounded-full bg-red-500 font-bold text-white">
          {messageThread[thread].unread}
        </span>
      )}
    </>
  );

  return (
    <div className="relative flex h-full max-h-[560px] flex-col justify-end bg-white md:max-h-[500px] md:rounded-[6px] lg:max-h-[580px] lg:rounded-[6px] lg:border lg:border-gray-200">
      {Object.keys(messageThread).length > 0 &&
        !messageThread[selectedThread] && <LoadingSpinner size="md" overlay />}
      <div
        className={`top-0 flex ${
          isCustomer ? 'h-[84px]' : 'h-[90px]'
        } w-full items-start gap-4 rounded-t-md border-b border-gray-400 bg-gray-50 px-5 pt-4`}
      >
        <div className="flex items-start gap-6">
          {chatActors &&
            Object.keys(chatActors)
              .filter((actor) => actor !== activePersona)
              .map((thread, index) => {
                const chat = chatActors[thread].filter(
                  (actor) => actor !== activePersona
                );
                if (chat.length > 1) {
                  return (
                    <div key={'chat-heads-' + thread} className="relative">
                      <div
                        className={`leading-0 font-nunito group relative relative flex h-10 w-10 items-center justify-center rounded-md bg-gray-200 outline outline-2 outline-offset-2 ${
                          selectedThread === thread
                            ? 'outline-primary-600'
                            : 'outline-gray-200'
                        } cursor-pointer text-xl font-bold text-gray-500 hover:border-gray-400`}
                        onClick={async () => await selectThread(thread)}
                        title={t('general.group')}
                      >
                        <UserGroupIcon />
                        <div className="absolute top-[calc(100%+10px)] left-0 z-10 hidden flex-col rounded-md bg-white shadow-lg group-hover:flex">
                          {chat.map((actor) => (
                            <div
                              className="flex cursor-default gap-2 border-b border-gray-200 p-2 last:border-0"
                              key={actor}
                            >
                              <UserIcon className="h-4 w-4" />
                              <span className="whitespace-nowrap text-base font-normal text-gray-600">
                                {(actorsInfo[actor] as DTConsumer_Private)
                                  ?.fullname ||
                                  (actorsInfo[actor] as DTRepairer_Public)
                                    ?.name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      {renderCaret(thread)}
                      {renderUnread(thread)}
                    </div>
                  );
                } else {
                  const actor = chat[0];
                  return (
                    <>
                      {(actorsInfo[actor] as DTConsumer_Private)?.splitname &&
                        !isCustomer && (
                          <div key={'chat-heads-' + index} className="relative">
                            <div
                              className={`relative flex h-10 w-10 cursor-pointer items-center justify-center rounded-[20px] bg-gray-200 bg-[linear-gradient(179.73deg,#FFFFFF_-108.09%,#C6C6C6_99.77%)] outline outline-2 outline-offset-2 ${
                                selectedThread === thread
                                  ? 'outline-primary-600'
                                  : 'outline-gray-200'
                              }`}
                              onClick={async () => await selectThread(thread)}
                              title={
                                (actorsInfo[actor] as DTConsumer_Private)
                                  ?.splitname?.first +
                                ' ' +
                                (actorsInfo[actor] as DTConsumer_Private)
                                  ?.splitname?.last
                              }
                            >
                              <span className="leading-0 font-nunito text-xl font-bold text-gray-500">
                                {
                                  (actorsInfo[actor] as DTConsumer_Private)
                                    ?.splitname?.first[0]
                                }
                                {
                                  (actorsInfo[actor] as DTConsumer_Private)
                                    ?.splitname?.last[0]
                                }
                              </span>
                            </div>
                            {renderCaret(thread, true)}
                            {renderUnread(thread)}
                          </div>
                        )}
                      {(actorsInfo[actor] as DTRepairer_Public)?.name &&
                        actor !== activePersona && (
                          <div key={'chat-heads-' + index} className="relative">
                            <div
                              className={`leading-0 font-nunito flex h-10 w-10 items-center justify-center rounded-md outline outline-2 outline-offset-2 ${
                                actor.includes(tenantLink)
                                  ? 'border-gray-200 bg-white'
                                  : 'bg-gray-200'
                              } ${
                                selectedThread === thread
                                  ? 'outline-primary-600'
                                  : 'outline-gray-200'
                              } cursor-pointer text-xl font-bold text-gray-500 hover:border-gray-400`}
                              onClick={async () => selectThread(thread)}
                              title={
                                (actorsInfo[actor] as DTRepairer_Public)?.name
                              }
                            >
                              {actor.includes(tenantLink) ? (
                                <img
                                  src={tenantInfo?.logo}
                                  alt="logo"
                                  className="bg-white"
                                />
                              ) : (
                                <span>
                                  {(
                                    actorsInfo[actor] as DTWarrantor_Public
                                  )?.name
                                    ?.toUpperCase()
                                    ?.slice(0, 2)}
                                </span>
                              )}
                            </div>
                            {renderCaret(thread)}
                            {renderUnread(thread)}
                          </div>
                        )}
                    </>
                  );
                }
              })}
        </div>
      </div>
      <div
        className={`bottom-0 flex ${
          isCustomer ? 'h-[calc(100%-64px-44px)]' : 'h-[calc(100%-64px)]'
        } relative z-10 flex-col-reverse gap-2 overflow-auto px-3 pb-5 lg:pb-2`}
      >
        <div>
          {(selectedThread && messageThread[selectedThread]
            ? getDaysArray(messageThread[selectedThread].messages)
            : []
          ).map((messageGroup: MessageGroupProps, index) => {
            return (
              <div key={index + '-chat-group'} className="flex flex-col gap-2">
                <ChatDate date={messageGroup.day} />
                {messageGroup.messages.map((message, index) => {
                  const sender =
                    (actorsInfo[message.sender] as DTConsumer_Private)
                      ?.fullname ||
                    (actorsInfo[message.sender] as DTWarrantor_Public)?.name;
                  let lastSender = '';
                  if (index > 0) {
                    lastSender =
                      (
                        actorsInfo[
                          messageGroup.messages[index - 1].sender
                        ] as DTConsumer_Private
                      )?.fullname ||
                      (
                        actorsInfo[
                          messageGroup.messages[index - 1].sender
                        ] as DTWarrantor_Public
                      )?.name;
                  }
                  return (
                    <ChatMessage
                      key={index + '-chat-message'}
                      wasSentByUser={
                        message.sender === activePersona ? true : false
                      }
                      sender={sender === lastSender ? '' : sender}
                      message={message.body}
                      timestamp={message.timestamp}
                      image={message.attachment}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {isUpdating && (
        <div className="pb-2">
          <LoadingSpinner size="xs" />
        </div>
      )}
      <Formik initialValues={initialValues} onSubmit={() => void 0}>
        {({ values, resetForm }) => (
          <ChatInputWrapper
            className={classNames(
              'absolute bottom-0 left-0 flex w-full gap-2 rounded-b-md border-t border-gray-200 bg-gray-50 px-4 py-2 lg:static',
              {
                'pointer-events-none opacity-50': isReadOnly,
              }
            )}
            handleImgSelection={handleFileEvent}
            handleSubmit={() => {
              if (values.chat_input.trim() === '') return;
              sendChatMessage(values.chat_input);
              resetForm();
            }}
          >
            <form
              className="w-full"
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  if (values.chat_input.trim() === '') return;
                  (event as any).target.style.height = '36px';
                  sendChatMessage(values.chat_input);
                  resetForm();
                }
              }}
            >
              <Input
                type="textarea"
                name="chat_input"
                style={{
                  height: '36px',
                  padding: '8px',
                  overflow: 'hidden',
                }}
                onInput={(e: any) => {
                  e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                }}
                settings={{
                  id: 'chat_input',
                  placeholder: `${t('message.writeTextHere')}...`,
                  allowSpecialCharacter: true,
                  onFocus: updateChatLastSeen,
                  disabled: !selectedThread,
                }}
              />
            </form>
          </ChatInputWrapper>
        )}
      </Formik>
    </div>
  );
}

export default Chat;
